header {
    background-color: #282c34;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 20px;
}

div.headerDiv:before {
    content: "";
    display: inline;
    height: 80px;
    vertical-align: middle;
}

div.headerDiv {
    font-size: 3rem;
    font-weight: bolder;
    text-align: center;
    width: 100%;
    vertical-align: middle;
}

.headerDiv img {
    padding: 0 15px 0 0;
    vertical-align: middle;
}

/* HAMBURGER MENU (MOBILE)   */
  
.hamburger {
  position: relative;
  display: block;
  width: 35px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  outline: none;
  border: none
}

.hamburger .bar,
.hamburger:after,
.hamburger:before {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    background-color: #FFF;
    margin: 6px 0px;
    -webkit-transition: 0.4s;
    transition: 0.4s
}

.hamburger.is-active:before {
    -webkit-transform: rotate(-45deg) translate(-8px, 6px);
    transform: rotate(-45deg) translate(-8px, 6px)
}

.hamburger.is-active:after {
    -webkit-transform: rotate(45deg) translate(-9px, -8px);
    transform: rotate(45deg) translate(-9px, -8px)
}

.hamburger.is-active .bar {
    opacity: 0
}

/* HAMBURGER MENU (SIDEBAR)   */
  
.sidehamburger {
  position: relative;
  display: block;
  width: 35px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  left: 0px;
  -webkit-transition: 0.4s;
  transition: 0.4s;  
}

.sidehamburger .bar,
.sidehamburger:after,
.sidehamburger:before {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    background-color: #FFF;
    margin: 6px 0px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.sidehamburger.is-active {
    left: 250px;
}

.sidehamburger.is-active:before {
    -webkit-transform: rotate(-45deg) translate(-8px, 6px);
    transform: rotate(-45deg) translate(-8px, 6px);
}

.sidehamburger.is-active:after {
    -webkit-transform: rotate(45deg) translate(-9px, -8px);
    transform: rotate(45deg) translate(-9px, -8px);
}

.sidehamburger.is-active .bar {
    opacity: 0
}
