.menuTitle {
  color: white;
  font-size: 3rem;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 30px;
}

.closeMobileMenu {
  color: white;
  font-size: 3rem;
  font-weight: bolder;
  text-align: center;
}

.closeMobileMenu:hover {
  cursor: pointer;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  min-height: 100vh;
  display: block;
  z-index: 98;
  background-color: #12002F;
  padding-top: 40px;
  -webkit-transition: 0.4s;
  transition: 0.4s
}

.mobile-nav.is-active {
  left: 0
}

.mobile-nav a {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto 16px;
  text-align: center;
  padding: 12px 16px;
  background-color: #4DBFFD;
  color: #1f103F;
  text-decoration: none;
  font-weight: 900;
}

.mobile-nav a.active {
  background-color: white;
  color: black;
}

.mobile-nav a:hover {
  background-color: #9ED3FF
}

@media (min-width: 768px) {
  .mobile-nav {
    display: none;
  }
  .hamburger {
    display: none;
  }
}
