.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: #12002F;
  transition: all 0.3s ease-in-out;
  border-top-right-radius: 20px;
  border-top: 2px solid #4DBFFD;
  border-right: 2px solid #4DBFFD;
  padding: 25px 0 0 0;
}

.sidebar.is-active {
  left: 0;
}

.sidebar a {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 16px auto;
  padding: 12px 16px;
  background-color: #4DBFFD;
  color: #1f103F;
  text-decoration: none;
  font-weight: 900;
}

.sidebar a.active {
  background-color: white;
  color: #1f103F;  
}

.sidebar a:hover {
  background-color: #9ED3FF;
  color: #1f103F;
}

@media (min-width: 767px) {
  .main-content.menu-open {
    margin-left: 0;
  }  
}

@media (max-width: 767px) {
  .sidehamburger {
    display: none;
  }
  .sidebar {
    display: none;
  }
}