
.bloglist {
    background-color: #282c34;
}
    
.blogitem {
    padding: 0 0 40px;
    background-color:ghostwhite;
    border-radius: 10px;
    border: 1px solid #4DBFFD;
    padding: 10px;
    margin: 0 20px 20px 20px;
}

.blogitem h2 {
    font-size: 1.5rem;
    width: 100%;
    padding: 0 0 10px;
}

#youtube_iframe {
    width:100%;
}