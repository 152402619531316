h1 {
    font-size: 3rem;
    font-weight: 900;
    padding: 0 0 0.5rem 0;
}

h2 {
    font-size: 2.5rem;
    font-weight: 600;
    padding: 0 0 0.5rem 0;
}

h3 {
    font-size: 2rem;
    font-weight: 300;
    padding: 0 0 0.5rem 0;
}

p {
    padding: 0 0 2rem 0;
}

