* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  background-color: #282c34;
}

a {
  color: inherit;
  text-decoration: none;
}

main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  width: 100%;
  transition: all 0.3s ease-in-out;
  padding-left: 0;
}

.pageItem {
  padding: 0 0 40px;
  background-color:ghostwhite;
  border-radius: 10px;
  border: 1px solid #4DBFFD;
  padding: 10px;
  margin: 0 20px 20px 20px;
}

@media (min-width: 767px) {
  .main-content.menu-open {
    padding-left: 250px;
  }
}